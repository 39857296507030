<template>
  <div class="">
    <div class="d-flex mb-2">
      <span class="page-header-title">Settings</span>
    </div>
    <div>
      <CCard>
        <CCardHeader class="border-bottom">
          <div class="d-flex btn-add-controller">
            <div class="title-font-class justify-content-between">
              <h5 class="pb-0 mt-2 font-describe-color">Mail Configuration</h5>
            </div>
            <div class="ml-auto">
              <div v-b-toggle.collapse-5 block>
                <em
                  style="color: #1e4c7c !important"
                  v-if="isVisibleMails"
                  class="fa-solid fa-2x fa fa-caret-down ml-1 mt-0"
                  aria-hidden="true"
                ></em>

                <em
                  style="color: #1e4c7c !important"
                  v-else
                  class="fa-solid fa-2x fa fa-caret-up ml-1 mt-0"
                  aria-hidden="true ml-1"
                ></em>
              </div>
            </div>
          </div>
        </CCardHeader>
        <b-collapse id="collapse-5" v-model="isVisibleMails">
          <CCardBody class="pb-2">
            <!-- <b-card class="mt-3">
            Service Request will be sent to this email id:
            <b>{{ serviceMailName }} </b>
            <i
              class="fa fa-edit fa-x ml-2 cursor"
              style="color: rgb(30, 76, 124)"
              @click="openServiceMail()"
            />
          </b-card> -->

            <div class="col-lg-12">
              <fieldset class="border field-set-border">
                <legend class="text-left ml-3 legend-items font-describe-color">
                  Service Request Mail IDs
                </legend>

                <div class="pl-3 pb-3">
                  <b>{{ serviceMailName }} </b>
                  <i
                    class="fa fa-edit fa-x ml-2 cursor"
                    style="color: rgb(30, 76, 124)"
                    @click="openServiceMail()"
                  />
                </div>
              </fieldset>
            </div>

            <!-- <b-card class="mt-3">
            Machine Registration will be sent to this email id:
            <b>{{ warrantyMailName }}</b>
            <i
              class="fa fa-edit fa-x ml-2 cursor"
              style="color: rgb(30, 76, 124)"
              @click="openWarrantyMail()"
            />
          </b-card> -->
            <div class="col-lg-12">
              <fieldset class="border field-set-border">
                <legend class="text-left ml-3 legend-items-machine">
                  Machine Registration Mail IDs
                </legend>

                <div class="pl-3 pb-3">
                  <b>{{ warrantyMailName }}</b>
                  <i
                    class="fa fa-edit fa-x ml-2 cursor"
                    style="color: rgb(30, 76, 124)"
                    @click="openWarrantyMail()"
                  />
                </div>
              </fieldset>
            </div>
            <!-- <b-card class="mt-3">
            Warranty Request will be sent to this email id:
            <b>{{ thirdMailName }}</b>
            <i
              class="fa fa-edit fa-x ml-2 cursor"
              style="color: rgb(30, 76, 124)"
              @click="openNewThirdMail()"
            />
          </b-card> -->
            <div class="col-lg-12">
              <fieldset class="border field-set-border">
                <legend class="text-left ml-3 legend-items-warranty">
                  Warranty Request Mail IDs
                </legend>

                <div class="pl-3 pb-3">
                  <b>{{ thirdMailName }}</b>
                  <i
                    class="fa fa-edit fa-x ml-2 cursor"
                    style="color: rgb(30, 76, 124)"
                    @click="openNewThirdMail()"
                  />
                </div>
              </fieldset>
            </div>
            <!-- <b-card class="mt-3">
            Customer Registration will be sent to this email id:
            <b>{{ fourthMailName }}</b>
            <i
              class="fa fa-edit fa-x ml-2 cursor"
              style="color: rgb(30, 76, 124)"
              @click="openNewFourthMail()"
            />
          </b-card> -->
            <div class="col-lg-12">
              <fieldset class="border field-set-border">
                <legend class="text-left ml-3 legend-items-customer">
                  Customer Registration Mail IDs
                </legend>

                <div class="pl-3 pb-3">
                  <b>{{ fourthMailName }}</b>
                  <i
                    class="fa fa-edit fa-x ml-2 cursor"
                    style="color: rgb(30, 76, 124)"
                    @click="openNewFourthMail()"
                  />
                </div>
              </fieldset>
            </div>
          </CCardBody>
        </b-collapse>
      </CCard>
      <CCard>
        <CCardHeader>
          <div class="d-flex btn-add-controller">
            <div class="title-font-class justify-content-between">
              <h5 class="pb-0 mt-2 font-describe-color">Controller Type</h5>
            </div>
            <div class="ml-auto">
              <div v-b-toggle.collapse-1 block>
                <em
                  style="color: #1e4c7c !important"
                  v-if="isVisible"
                  class="fa-solid fa-2x fa fa-caret-down ml-1 mt-0"
                  aria-hidden="true"
                ></em>

                <em
                  style="color: #1e4c7c !important"
                  v-else
                  class="fa-solid fa-2x fa fa-caret-up ml-1 mt-0"
                  aria-hidden="true ml-1"
                ></em>
              </div>
            </div>
          </div>
        </CCardHeader>

        <b-collapse id="collapse-1" v-model="isVisible">
          <CCardBody>
            <div class="mb-3 float-right">
              <b-button variant="primary" @click="addCategory()">
                <em class="fa fa-plus" aria-hidden="true"></em>
                Add New
              </b-button>
            </div>
            <div class="table-responsive controller p-0">
              <table
                id="ctable"
                class="table table-striped table-fixed table-hover table-bordered"
                inline
                aria-describedby="campaignTbl"
              >
                <thead>
                  <tr>
                    <th class="card-lables w-70" id="col3">Controller Type</th>
                  </tr>
                </thead>
                <draggable
                  tag="tbody"
                  handle=".handle"
                  @start="drag = true"
                  v-model="controllerList"
                  @end="onDraggingCategory"
                >
                  <tr
                    class="border-bottom mt-2 mb-2 periodSetting-row"
                    v-for="(item, pIndex) in controllerList"
                    :key="pIndex"
                  >
                    <td>
                      <div class="d-flex">
                        <img
                          class="handle dragger-custom cursor"
                          width="30px"
                          height="30px"
                          v-bind:src="dragIcon"
                          alt="Groony Logo"
                          :id="item.sort_order"
                        />
                        <span class="text-danger mr-1 mt-2"></span>
                        <CInput
                          v-model.trim="controllerList[pIndex]['name']"
                          placeholder="Controller Type"
                          type="text"
                          class="col-lg-11 cols-6 m-0"
                          required
                          was-validated
                          @keydown="onTypePreventSomeSpecialKeys($event)"
                          @paste="onPastePreventSomeSpecialKeys($event)"
                        />
                        <em
                          class="fa fa-times-circle cursor mt-2 pt-1 pull-right"
                          @click="removeContactPark(pIndex)"
                          aria-hidden="true"
                          style="color: red"
                        />
                      </div>
                      <show-errors
                        class="ml-5 mt-1"
                        :control="$v.controllerList.$each[pIndex]['name']"
                        :field="
                          $t(
                            'app.master.tab.basicinfo.kinderinputIP.placeholder'
                          )
                        "
                      />
                    </td>
                  </tr>
                </draggable>
              </table>
              <p class="text-center" v-if="!controllerList.length">
                {{ $t("app.generic.norecordsfound") }}
              </p>
            </div>

            <CRow class="mt-3">
              <CCol col="12" class="text-right">
                <CButton
                  color="primary"
                  class="px-2"
                  @click="saveSortOfController()"
                  >Save</CButton
                >
              </CCol>
            </CRow>
          </CCardBody>
        </b-collapse>
      </CCard>
      <CCard>
        <CCardHeader>
          <div class="d-flex btn-add-controller">
            <div class="title-font-class justify-content-between">
              <h5 class="pb-0 mt-2 font-describe-color">MTB Name</h5>
            </div>
            <div class="ml-auto">
              <div v-b-toggle.collapse-2 block>
                <em
                  style="color: #1e4c7c !important"
                  v-if="isVisibleMtb"
                  class="fa-solid fa-2x fa fa-caret-down ml-1 mt-0"
                  aria-hidden="true"
                ></em>

                <em
                  style="color: #1e4c7c !important"
                  v-else
                  class="fa-solid fa-2x fa fa-caret-up ml-1 mt-0"
                  aria-hidden="true ml-1"
                ></em>
              </div>
            </div>
          </div>
        </CCardHeader>
        <b-collapse id="collapse-2" v-model="isVisibleMtb">
          <CCardBody>
            <div class="mb-3 float-right">
              <b-button variant="primary" @click="addCategoryMtb()">
                <em class="fa fa-plus" aria-hidden="true"></em>
                Add New
              </b-button>
            </div>
            <div class="table-responsive controller p-0">
              <table
                id="ctable"
                class="table table-striped table-fixed table-hover table-bordered"
                inline
                aria-describedby="campaignTbl"
              >
                <thead>
                  <tr>
                    <th class="card-lables w-70" id="col3">MTB Name</th>
                  </tr>
                </thead>
                <draggable
                  tag="tbody"
                  handle=".handle"
                  v-model="mtbList"
                  @start="drag = true"
                  @end="onDraggingMtb"
                >
                  <tr
                    class="border-bottom mt-2 mb-2 periodSetting-row"
                    v-for="(item, pIndex) in mtbList"
                    :key="pIndex"
                  >
                    <td>
                      <div class="d-flex">
                        <img
                          class="handle dragger-custom cursor"
                          width="30px"
                          height="30px"
                          v-bind:src="dragIcon"
                          alt="Groony Logo"
                          :id="item.sort_order"
                        />
                        <span class="text-danger mr-1 mt-2"></span>
                        <CInput
                          v-model.trim="mtbList[pIndex]['name']"
                          placeholder="MTB Name"
                          type="text"
                          class="col-lg-11 cols-6 m-0"
                          required
                          was-validated
                          @keydown="onTypePreventSomeSpecialKeys($event)"
                          @paste="onPastePreventSomeSpecialKeys($event)"
                        />

                        <em
                          class="fa fa-times-circle cursor mt-2 pt-1 pull-right"
                          @click="removeMtb(pIndex)"
                          aria-hidden="true"
                          style="color: red"
                        />
                      </div>
                      <show-errors
                        class="ml-5 mt-1"
                        :control="$v.mtbList.$each[pIndex]['name']"
                        :field="
                          $t(
                            'app.master.tab.basicinfo.kinderinputIP.placeholder'
                          )
                        "
                      />
                    </td>
                  </tr>
                </draggable>
              </table>
              <p class="text-center" v-if="!mtbList.length">
                {{ $t("app.generic.norecordsfound") }}
              </p>
            </div>

            <CRow class="mt-3">
              <CCol col="12" class="text-right">
                <CButton color="primary" class="px-2" @click="saveSortOfMtb()"
                  >Save</CButton
                >
              </CCol>
            </CRow>
          </CCardBody>
        </b-collapse>
      </CCard>

      <CCard>
        <CCardHeader>
          <div class="d-flex btn-add-controller">
            <div class="title-font-class justify-content-between">
              <h5 class="pb-0 mt-2 font-describe-color">Region Name</h5>
            </div>
            <div class="ml-auto">
              <div v-b-toggle.collapse-3 block>
                <em
                  style="color: #1e4c7c !important"
                  v-if="isVisibleRegion"
                  class="fa-solid fa-2x fa fa-caret-down ml-1 mt-0"
                  aria-hidden="true"
                ></em>

                <em
                  style="color: #1e4c7c !important"
                  v-else
                  class="fa-solid fa-2x fa fa-caret-up ml-1 mt-0"
                  aria-hidden="true ml-1"
                ></em>
              </div>
            </div>
          </div>
        </CCardHeader>
        <b-collapse id="collapse-3" v-model="isVisibleRegion">
          <CCardBody>
            <div class="mb-3 float-right">
              <b-button
                variant="primary"
                class="mr-2"
                @click="openRegionMasterModal()"
              >
                <em class="fa fa-plus" aria-hidden="true"></em>
                Regions Mapping
              </b-button>
              <b-button variant="primary" @click="addCategoryRegionMaster()">
                <em class="fa fa-plus" aria-hidden="true"></em>
                Add New
              </b-button>
            </div>
            <div class="table-responsive controller p-0">
              <table
                id="ctable"
                class="table table-striped table-fixed table-hover table-bordered"
                inline
                aria-describedby="campaignTbl"
              >
                <thead>
                  <tr>
                    <th class="card-lables w-70" id="col3">Region Name</th>
                  </tr>
                </thead>
                <draggable
                  tag="tbody"
                  handle=".handle"
                  v-model="regionMasterList"
                  @start="drag = true"
                  @end="onDraggingRegion"
                >
                  <tr
                    class="border-bottom mt-2 mb-2 periodSetting-row"
                    v-for="(item, pIndex) in regionMasterList"
                    :key="pIndex"
                  >
                    <td>
                      <div class="d-flex">
                        <img
                          class="handle dragger-custom cursor"
                          width="30px"
                          height="30px"
                          v-bind:src="dragIcon"
                          alt="Groony Logo"
                          :id="item.sort_order"
                        />
                        <span class="text-danger mr-1 mt-2"></span>
                        <CInput
                          :disabled="item.state == 1"
                          v-model.trim="regionMasterList[pIndex]['name']"
                          placeholder="Region Name"
                          type="text"
                          class="col-lg-11 cols-6 m-0"
                          required
                          was-validated
                        />

                        <em
                          v-if="item.state == 0"
                          disabled
                          class="fa fa-times-circle cursor mt-2 pt-1 pull-right"
                          @click="removeRegion(pIndex)"
                          aria-hidden="true"
                          style="color: red"
                        />
                        <em
                          v-else-if="item.state == 1"
                          disabled
                          class="fa fa-times-circle disabled-em-elementcursor mt-2 pt-1 pull-right"
                          aria-hidden="true"
                          style="color: red"
                        />
                        <em
                          v-else
                          class="fa fa-times-circle cursor mt-2 pt-1 pull-right"
                          @click="removeRegion(pIndex)"
                          aria-hidden="true"
                          style="color: red"
                        />
                      </div>
                      <show-errors
                        class="ml-5 mt-1"
                        :control="$v.regionMasterList.$each[pIndex]['name']"
                        :field="
                          $t(
                            'app.master.tab.basicinfo.kinderinputIP.placeholder'
                          )
                        "
                      />
                    </td>
                  </tr>
                </draggable>
              </table>
              <p class="text-center" v-if="!regionMasterList.length">
                {{ $t("app.generic.norecordsfound") }}
              </p>
            </div>

            <CRow class="mt-3">
              <CCol col="12" class="text-right">
                <CButton
                  color="primary"
                  class="px-4"
                  @click="saveSortOfRegionMaster()"
                  >Save</CButton
                >
              </CCol>
            </CRow>
          </CCardBody>
        </b-collapse>
      </CCard>

      <CCard>
        <CCardHeader>
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="pb-0 mt-2 font-describe-color">Week Off</h5>
            <div v-b-toggle.collapse-6 block>
              <em
                v-if="isVisibleWeekOff"
                class="fa-solid fa-2x fa fa-caret-down ml-1 mt-0"
                style="color: #1e4c7c !important"
                aria-hidden="true"
              ></em>
              <em
                v-else
                class="fa-solid fa-2x fa fa-caret-up ml-1 mt-0"
                style="color: #1e4c7c !important"
                aria-hidden="true"
              ></em>
            </div>
          </div>
        </CCardHeader>
        <b-collapse id="collapse-6" v-model="isVisibleWeekOff">
          <CCardBody>
            <div
              v-for="(item, pIndex) in regionMasterWeekOffList"
              :key="pIndex"
              class=""
            >
              <div class="col-12 d-flex flex-column flex-md-row mb-3">
                <span
                  class="font-weight-bold col-12 col-md-4 m-0 p-0 mb-2 text-truncate"
                  >{{ item.name }}</span
                >
                <div class="col-12 col-md-4 m-0 p-0 mb-2 mr-md-4">
                  <multi-select
                    class="w-100"
                    :options="weekdays"
                    v-model="item.week_off"
                  ></multi-select>
                  <show-errors
                    class="mt-1"
                    :control="
                      $v.regionMasterWeekOffList.$each[pIndex]['week_off']
                    "
                    :field="
                      $t('app.master.tab.basicinfo.kinderinputIP.placeholder')
                    "
                  />
                </div>
                <!-- <CSelect class="col-12 col-md-4 m-0 p-0 mb-2 mr-md-4" v-model="item.selectedWeekday" :options="weekdays" placeholder="Select Weekday"></CSelect> -->
              </div>
            </div>
            <p class="text-center" v-if="!regionMasterWeekOffList.length">
              {{ $t("app.generic.norecordsfound") }}
            </p>
            <CRow class="mt-3">
              <CCol col="12" class="text-right">
                <div>
                  <CButton color="primary px-4" @click="submitRegionWeekOff()"
                    >Save</CButton
                  >
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </b-collapse>
      </CCard>

      <b-modal
        id="serverEmail"
        title="Update Service Request Email ID"
        ok-title="Save"
        cancel-title="Cancel"
      >
        <label class="mt-2 asterisk font-weight-bold">Email ID </label>
        <p class="settings-input">
          <CInput
            v-model.trim="serviceMail"
            required
            was-validated
            placeholder="Email ID"
          />
          <show-errors
            class=""
            :control="$v.serviceMail"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <template v-slot:modal-footer="{}">
          <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
            <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
              <b-button
                size="md"
                variant="secondary"
                class="ml-2 px-2"
                @click="$bvModal.hide('serverEmail')"
                >Cancel
              </b-button>
              <b-button
                size="md"
                variant="primary"
                class="ml-2 px-2"
                :disabled="isLoaderOn"
                @click="saveServiceMail()"
                >Save
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>

      <b-modal
        id="newFourthEmail"
        title="Update Customer Registration Email ID"
        ok-title="Save"
        cancel-title="Cancel"
      >
        <label class="mt-2 asterisk font-weight-bold">Email ID </label>
        <p class="settings-input">
          <CInput
            placeholder="Email ID"
            v-model.trim="fourth_mail"
            type="text"
            required
            was-validated
          />
          <show-errors
            :control="$v.fourth_mail"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <template v-slot:modal-footer="{}">
          <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
            <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
              <b-button
                size="md"
                variant="secondary"
                class="ml-2 px-2"
                @click="$bvModal.hide('newFourthEmail')"
                >Cancel
              </b-button>
              <b-button
                size="md"
                variant="primary"
                class="ml-2 px-2"
                :disabled="isLoaderOn"
                @click="saveFourthMail()"
                >Save
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>

      <b-modal
        id="newThirdEmail"
        title="Update Warranty Request  Email ID"
        ok-title="Save"
        cancel-title="Cancel"
      >
        <label class="mt-2 asterisk font-weight-bold">Email ID </label>
        <p class="settings-input">
          <CInput
            placeholder="Email ID"
            v-model.trim="third_mail"
            type="text"
            required
            was-validated
          />
          <show-errors
            :control="$v.third_mail"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <template v-slot:modal-footer="{}">
          <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
            <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
              <b-button
                size="md"
                variant="secondary"
                class="ml-2 px-2"
                @click="$bvModal.hide('newThirdEmail')"
                >Cancel
              </b-button>
              <b-button
                size="md"
                variant="primary"
                class="ml-2 px-2"
                :disabled="isLoaderOn"
                @click="saveThirdMail()"
                >Save
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>

      <b-modal
        id="warrantyEmail"
        title="Update Machine Registration Email ID"
        ok-title="Save"
        cancel-title="Cancel"
      >
        <label class="mt-2 asterisk font-weight-bold">Email ID </label>
        <p class="settings-input">
          <CInput
            placeholder="Email ID"
            v-model.trim="warrantyMail"
            type="text"
            required
            was-validated
          />
          <show-errors
            :control="$v.warrantyMail"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <template v-slot:modal-footer="{}">
          <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
            <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
              <b-button
                size="md"
                variant="secondary"
                class="ml-2 px-2"
                @click="$bvModal.hide('warrantyEmail')"
                >Cancel
              </b-button>
              <b-button
                size="md"
                variant="primary"
                class="ml-2 px-2"
                :disabled="isLoaderOn"
                @click="saveWarrantyMail()"
                >Save
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>

      <b-modal
        id="regionModal"
        title="Regions Mapping"
        ok-title="Save"
        cancel-title="Cancel"
        size="lg"
      >
        <div class="mt-3">
          <div class="d-md-flex justify-content-end mt-3">
            <div>
              <b-input-group>
                <template #append>
                  <b-input-group-text v-model="search">
                    <CIcon name="cil-search" class="cicon cursor" />
                  </b-input-group-text>
                </template>
                <b-form-input v-model="search" placeholder="Search">
                </b-form-input>
              </b-input-group>
            </div>
          </div>
          <div
            v-if="regionsAllList.length > 0"
            class="table-responsive entry-custom-calender-responsive mt-3"
          >
            <table
              ref="calendarTable"
              class="entry-custom-calender table table-bordered table-striped"
              aria-describedby="calTable"
            >
              <tr>
                <th scope="col" class="sticky th-top-class text-bgm">
                  Country
                </th>
                <th scope="col" class="sticky th-top-class text-bgm">State</th>
                <th scope="col" class="sticky th-top-class text-bgm">Region</th>
              </tr>

              <tbody class="value-bgm">
                <tr v-for="values in filteredItems" :key="values.id">
                  <td v-if="values.country == 0">
                    <div class="d-flex margin-top-value">India</div>
                  </td>
                  <td v-else-if="values.country == 1">
                    <div class="d-flex margin-top-value">Sri Lanka</div>
                  </td>
                  <td v-else>
                    <div class="d-flex margin-top-value">Bangladesh</div>
                  </td>
                  <td>
                    <div class="d-flex margin-top-value">
                      {{ values.state_name }}
                    </div>
                  </td>
                  <td class="padding-select-box">
                    <div class="d-flex">
                      <b-form-select
                        v-model="values.region_id"
                        :options="allRegionsDataList"
                      ></b-form-select>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="filteredItems.length == 0">
              <center>No data found.</center>
            </div>
          </div>
        </div>

        <template v-slot:modal-footer="{}">
          <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
            <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
              <b-button
                size="md"
                variant="secondary"
                class="ml-2 px-2"
                @click="$bvModal.hide('regionModal')"
                >Cancel
              </b-button>
              <b-button
                :disabled="clickedOnce == true"
                size="md"
                variant="primary"
                class="ml-2 px-2"
                v-on:click="saveAllRegionsData()"
                >Save
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import draggable from "vuedraggable";
import ShowErrors from "@/components/ShowErrors/ShowErrors.vue";
import { required, maxLength } from "vuelidate/lib/validators";
import MultiSelect from "@/components/MultiSelect/multiSelect.vue";
import {
  multipleErrorMessages,
  multipleEmailValidation,
  senitize,
  senitizeMtbName,
  senitizeNcModel,
} from "@/shared/utils";
import {
  SETTINGS_CONTROLLER_LIST,
  SETTINGS_MTB_LIST,
  SERVICE_MAIL_ADD,
  SERVICE_MAIL_LIST,
  SETTINGS_SORT_LIST,
  SETTINGS_SORT_SAVE,
  SETTINGS_REGION_SORT_LIST,
  SETTINGS_REGION_MASTER_SAVE,
  STORE_REGION_DATA,
  GET_REGION_DATA,
  SETTINGS_REGION_WEEK_OFF_SORT_LIST,
  SETTINGS_REGION_WEEK_OFF_SAVE,
} from "@/store/actions.type";

export default {
  name: "SettingsDetails",
  components: { draggable, ShowErrors, MultiSelect },
  data() {
    return {
      search: "",
      clickedOnce: false,
      currentPageOfSettings: 1,
      regionsAllList: [],
      controllerId: "",
      mtbId: "",
      controllerType: 1,
      totalMTB: 0,
      totalController: 0,
      mtbName: "",
      controllerName: "",
      MTBName: 1,
      serviceMail: "",
      warrantyMail: "",
      third_mail: "",
      fourth_mail: "",
      serviceMailName: "",
      warrantyMailName: "",
      thirdMailName: "",
      thirdMailID: "",
      fourthMailName: "",
      fourthMailID: "",
      serviceMailId: "",
      warrantyMailId: "",
      controllerList: [],
      mtbList: [],
      regionMasterList: [{}],
      regionMasterWeekOffList: [{}],
      options: [
        { value: "option1", text: "Option 1" },
        { value: "option2", text: "Option 2" },
        { value: "option3", text: "Option 3" },
      ],
      selectedOptions: [],
      controllerFilters: {
        start: "",
        limit: "",
        order_by: "",
        sort_by: "",
      },
      mtbFilters: {
        start: "",
        limit: "",
        order_by: "",
        sort_by: "",
      },
      isVisible: false,
      isVisibleMtb: false,
      isVisibleRegion: false,
      isVisibleMails: false,
      isVisibleWeekOff: false,
      currentPage: 1,
      pageStart: 0,
      perPage: 5,
      sortField: "created_at",
      sortDirection: "desc",
      currentPageController: 1,
      pageStartController: 0,
      perPageController: 5,
      sortFieldController: "created_at",
      sortDirectionController: "desc",
      serviceAllMail: [],
      CurrentRegionTable: 1,
      dragIcon: `${process.env.BASE_URL}img/DragIcon.png`,
      fields: [
        {
          key: "country",
          sorter: false,
          label: "Country",
          _classes: "countryName",
        },
        {
          key: "state_name",
          label: "State",
          sortable: false,
          _classes: "stateName",
        },
        {
          key: "region_id",
          label: "Region",
          sorter: false,
          _classes: "regionCombo ",
        },
      ],
      regionComboList: [],
      allRegionsDataList: [],
      total: 0,
      isLoaderOn: false,
      weekdays: [
        { label: "sunday", value: 7 },
        { label: "monday", value: 1 },
        { label: "tuesday", value: 2 },
        { label: "wednesday", value: 3 },
        { label: "thursday", value: 4 },
        { label: "friday", value: 5 },
        { label: "saturday", value: 6 },
      ],
    };
  },
  //validation for the fields
  validations: {
    mtbName: { required, maxLength: maxLength(255) },
    controllerName: { required, maxLength: maxLength(255) },
    serviceMail: {
      required,
      multipleEmailValidation,
      maxLength: maxLength(255),
    },
    warrantyMail: {
      required,
      multipleEmailValidation,
      maxLength: maxLength(255),
    },
    third_mail: {
      required,
      multipleEmailValidation,
      maxLength: maxLength(255),
    },
    fourth_mail: {
      required,
      multipleEmailValidation,
      maxLength: maxLength(255),
    },
    controllerList: {
      $each: {
        name: {
          required,
          maxLength: maxLength(255),
          senitizeNcModel,
        },
      },
    },
    mtbList: {
      $each: {
        name: {
          required,
          maxLength: maxLength(255),
          senitizeMtbName,
        },
      },
    },
    regionMasterList: {
      $each: {
        name: {
          required,
          maxLength: maxLength(255),
          senitize,
        },
      },
    },
    regionMasterWeekOffList: {
      $each: {
        week_off: {
          required,
        },
      },
    },
    regionsAllList: [],
  },
  methods: {
    /**
     * @method onTypePreventSomeSpecialKeys
     * @description Tt will prevent typing some special characters, that special characters are `"'[]'"
     * @author @Jeevan Poojary
     */
    onTypePreventSomeSpecialKeys(e) {
      let preventRegex = /^[a-z0-9 .~!@#$%^&*()_=+{}|?/<>,:;-]+$/gi;
      if (!preventRegex.test(e.key) || e.key == "Dead") {
        e.preventDefault();
        return false;
      }
    },

    /**
     * @method onPastePreventSomeSpecialKeys
     * @description Tt will prevent pasting some special characters, that special characters are `"'[]'"
     * @author @Jeevan Poojary
     */
    onPastePreventSomeSpecialKeys(e) {
      let preventRegex = /^[a-z0-9 .~!@#$%^&*()_=+{}|?/<>,:;-]+$/gi;
      let key = e.clipboardData.getData("text");
      if (!preventRegex.test(key)) {
        e.preventDefault();
        return false;
      }
    },

    /**
     * @method getAllRegionsListItems
     * @description Tt will fetch all users data
     * @author @Jeevan Poojary
     */

    async getAllRegionsListItems() {
      let data = await this.$store.dispatch(GET_REGION_DATA, {
        filters: {
          start: 0,
         limit: 50,
        },
      });

      if (_.get(data, "success", true)) {
        this.regionsAllList = _.get(data, "list", []);
        this.total = _.get(data.list, "total", 0);
      } else {
        let message = _.get(data, "message", "");
        this.regionsAllList = [];
        this.total = 0;
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    /**
     * @method addCategoryMtb
     * @description Method to add input field rows on click of add category button
     * @author @jeevan-agent
     */
    addCategoryMtb: function () {
      setTimeout(() => {
        this.$v.mtbList.$reset();
      }, 0);
      this.mtbList.push({
        name: "",
        sort_order: "",
        type: 2,
      });
    },
    /**
     * @method addCategory
     * @description Method to add input field rows on click of add category button
     * @author @jeevan-agent
     */
    addCategory: function () {
      setTimeout(() => {
        this.$v.controllerList.$reset();
      }, 0);
      this.controllerList.push({
        name: "",
        sort_order: "",
        type: 1,
      });
    },

    /**
     * @method addCategoryRegionMaster
     * @description Method to add input field rows on click of add category button
     * @author @jeevan-agent
     */
    addCategoryRegionMaster: function () {
      setTimeout(() => {
        this.$v.regionMasterList.$reset();
      }, 0);
      this.regionMasterList.push({
        name: "",
        sort_order: "",
      });
    },
    /**
     * @method removeContactPark
     * @description Method to remove each rows on click of x icon form contacts
     * @author @jeevan-agent
     */
    removeContactPark(rowIndex) {
      if (this.controllerList.length >= 1)
        this.controllerList = _.filter(
          this.controllerList,
          (item, index) => index != rowIndex
        );
    },

    /**
     * @method removeContactPark
     * @description Method to remove each rows on click of x icon form contacts
     * @author @jeevan-agent
     */
    removeMtb(rowIndex) {
      if (this.mtbList.length >= 1)
        this.mtbList = _.filter(
          this.mtbList,
          (item, index) => index != rowIndex
        );
    },

    /**
     * @method removeRegion
     * @description Method to remove each rows on click of x icon form contacts
     * @author @jeevan-agent
     */
    removeRegion(rowIndex) {
      if (this.regionMasterList.length >= 1)
        this.regionMasterList = _.filter(
          this.regionMasterList,
          (item, index) => index != rowIndex
        );
    },

    /**
     * @method saveSortOfMtb
     * @description API call for saving mtb details
     * Calling this method on click of save button
     * @author @jeevan-poojary
     */
    async saveSortOfMtb() {
      this.$v.$touch();
      if (!this.$v.mtbList.$invalid) {
        /** Adding sort_order position for each object of category class before sending it into payload */
        this.mtbList.forEach((role, index) => {
          if (!_.get(role, "sort_order", 0)) {
            role.sort_order = index + 1;
            role.type = 2;
          }
        });
        let payload = {
          type: 2,
          device_details: this.mtbList,
        };
        this.isLoadingSaveMtb = this.$loading.show();
        await this.$store.dispatch(SETTINGS_SORT_SAVE, payload).then((data) => {
          if (_.get(data, "data.success", false)) {
            this.isLoadingSaveMtb.hide();
            this.$toast.removeAll();
            this.$toast.success({
              message: _.get(data, "data.message", ""),
            });

            this.controllerName = "";
            this.$v.controllerName.$reset();
            this.fetchControllerSortList();
            this.fetchMtbSortList();
          } else {
            this.isLoadingSaveMtb.hide();
            this.$toast.removeAll();
            let message = _.get(data, "message", "");
            if (message.length > 0) {
              this.$toast.error({
                message: message,
              });
            } else {
              this.$toast.removeAll();
              let messageObj = _.get(data, "message", "");
              multipleErrorMessages(this.$toast, messageObj);
            }
            this.fetchMtbSortList();
          }
        });
        this.isLoadingSaveMtb.hide();
      }
    },

    /**
     * @method saveAllRegionsData
     * @description API call for saving regions all details
     * Calling this method on click of save button
     * @author @jeevan-poojary
     */
    async saveAllRegionsData() {
      this.clickedOnce = true;
      this.$v.$touch();
      if (!this.$v.regionsAllList.$invalid) {
        /** Adding sort_order position for each object of category class before sending it into payload */
        this.regionsAllList.forEach((role) => {
          delete role.region_name;
        });

        let payload = {
          region_data: this.regionsAllList,
        };
        this.isLoadingSaveRegions = this.$loading.show();
        await this.$store.dispatch(STORE_REGION_DATA, payload).then((data) => {
          if (_.get(data, "data.success", false)) {
            this.isLoadingSaveRegions.hide();
            this.$toast.removeAll();
            this.$toast.success({
              message: _.get(data, "data.message", ""),
            });
            this.getRegionsDropdownList();
            this.getAllRegionsListItems();
            this.$bvModal.hide("regionModal");
          } else {
            this.isLoadingSaveRegions.hide();
            this.$bvModal.hide("regionModal");
            let message = _.get(data, "message", "");
            if (message.length > 0) {
              this.$toast.error({
                message: message,
              });
            } else {
              this.$toast.removeAll();
              let messageObj = _.get(data, "message", "");
              multipleErrorMessages(this.$toast, messageObj);
            }
          }
        });
        this.isLoadingSaveRegions.hide();
      }
    },

    /**
     * @method saveSortOfController
     * @description API call for saving controller details
     * Calling this method on click of save button
     * @author @jeevan-poojary
     */
    async saveSortOfController() {
      this.$v.$touch();
      if (!this.$v.controllerList.$invalid) {
        /** Adding sort_order position for each object of category class before sending it into payload */
        this.controllerList.forEach((role, index) => {
          if (!_.get(role, "sort_order", 0)) {
            role.sort_order = index + 1;
            role.type = 1;
          }
        });
        let payload = {
          type: 1,
          device_details: this.controllerList,
        };
        this.isLoadingSaveController = this.$loading.show();
        await this.$store.dispatch(SETTINGS_SORT_SAVE, payload).then((data) => {
          if (_.get(data, "data.success", false)) {
            this.isLoadingSaveController.hide();
            this.$toast.removeAll();
            this.$toast.success({
              message: _.get(data, "data.message", ""),
            });

            this.controllerName = "";
            this.$v.controllerName.$reset();
            this.fetchMtbSortList();
            this.fetchControllerSortList();
          } else {
            this.isLoadingSaveController.hide();

            let message = _.get(data, "message", "");
            if (message.length > 0) {
              this.$toast.error({
                message: message,
              });
            } else {
              this.$toast.removeAll();
              let messageObj = _.get(data, "message", "");
              multipleErrorMessages(this.$toast, messageObj);
            }
          }
        });
        this.isLoadingSaveController.hide();
      }
    },

    /**
     * @method saveSortOfMtb
     * @description API call for saving mtb details
     * Calling this method on click of save button
     * @author @jeevan-poojary
     */
    async saveSortOfRegionMaster() {
      this.$v.$touch();
      if (!this.$v.regionMasterList.$invalid) {
        /** Adding sort_order position for each object of category class before sending it into payload */
        this.regionMasterList.forEach((role, index) => {
          if (!_.get(role, "sort_order", 0)) {
            role.sort_order = index + 1;
          }
        });
        this.regionMasterList.forEach((role) => {
          delete role.state;
        });

        let payload = {
          region_details: this.regionMasterList,
        };
        this.isLoadingRegionMaster = this.$loading.show();
        await this.$store
          .dispatch(SETTINGS_REGION_MASTER_SAVE, payload)
          .then((data) => {
            if (_.get(data, "data.success", false)) {
              this.isLoadingRegionMaster.hide();
              this.$toast.removeAll();
              this.$toast.success({
                message: _.get(data, "data.message", ""),
              });
              this.fetchRegionMasterSortList();
              this.getAllRegionsListItems();
              this.getRegionsDropdownList();
              this.getRegionWeekOffList();
            } else {
              this.isLoadingRegionMaster.hide();
              let message = _.get(data, "message", "");
              if (message.length > 0) {
                this.$toast.error({
                  message: message,
                });
              } else {
                this.$toast.removeAll();
                let messageObj = _.get(data, "message", "");
                multipleErrorMessages(this.$toast, messageObj);
              }
            }
          });
        this.isLoadingRegionMaster.hide();
      }
    },

    /**
     * @method onDraggingMtb
     * @description Method to get the sort_order position of dragged input fiels based on index for category
     * @author @jeevan-agent
     */
    async onDraggingMtb() {
      this.mtbList.forEach((role, index) => {
        role.sort_order = index + 1;
      });
    },

    /**
     * @method onDraggingCategory
     * @description Method to get the sort_order position of dragged input fiels based on index for category
     * @author @jeevan-agent
     */
    async onDraggingCategory() {
      this.controllerList.forEach((role, index) => {
        role.sort_order = index + 1;
      });
    },
    /**
     * @method onDraggingRegion
     * @description Method to get the sort_order position of dragged input fiels based on index for category
     * @author @jeevan-agent
     */
    async onDraggingRegion() {
      this.regionMasterList.forEach((role, index) => {
        role.sort_order = index + 1;
      });
    },

    /**
     * @method fetchControlList
     * @description Tt will fetch all controller data
     * @author @Jeevan Poojary
     */

    async fetchControlList() {
      this.isLoadingController = this.$loading.show();
      let data = await this.$store.dispatch(SETTINGS_CONTROLLER_LIST, {
        filters: this.controllerFilters,
      });
      this.isLoadingController.hide();
      if (_.get(data, "success", true)) {
        this.controllerList = _.get(data, "list", []);
        this.totalController = _.get(data, "total", 0);
      } else {
        let message = _.get(data, "message", "");
        this.controllerList = [];
        this.totalController = 0;
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    /**
     * @method openServiceMail
     * @description create new data popup opens
     * @author @Jeevan Poojary
     */
    openServiceMail() {
      this.serviceMail = this.serviceMailName;
      this.$bvModal.show("serverEmail");
    },

    /**
     * @method openWarrantyMail
     * @description create new data popup opens
     * @author @Jeevan Poojary
     */
    openWarrantyMail() {
      this.warrantyMail = this.warrantyMailName;
      this.$bvModal.show("warrantyEmail");
    },

    /**
     * @method openNewThirdMail
     * @description create new data popup opens
     * @author @Jeevan Poojary
     */
    openNewThirdMail() {
      this.third_mail = this.thirdMailName;
      this.$bvModal.show("newThirdEmail");
    },

    /**
     * @method openNewFourthMail
     * @description create new data popup opens
     * @author @Jeevan Poojary
     */
    openNewFourthMail() {
      this.fourth_mail = this.fourthMailName;
      this.$bvModal.show("newFourthEmail");
    },

    /**
     * @method openRegionMasterModal
     * @description create new data popup opens
     * @author @Jeevan Poojary
     */
    async openRegionMasterModal() {
      this.clickedOnce = false;
      this.$bvModal.show("regionModal");
      this.getRegionsDropdownList();
    },

    /**
     * @method serviceMailList
     * @description Tt will fetch all mail data
     * @author @Jeevan Poojary
     */

    async serviceMailList() {
      let data = await this.$store.dispatch(SERVICE_MAIL_LIST);
      if (_.get(data, "success", true)) {
        this.serviceAllMail = data;
        let serviceValueObject = this.serviceAllMail.filter(function (elem) {
          if (elem.mail_type == 1) return elem;
        });
        this.serviceMail = serviceValueObject[0].email;
        this.serviceMailName = serviceValueObject[0].email;
        this.serviceMailId = serviceValueObject[0].id;

        let warrantyValObj = this.serviceAllMail.filter(function (elem) {
          if (elem.mail_type == 2) return elem;
        });
        this.warrantyMail = warrantyValObj[0].email;
        this.warrantyMailName = warrantyValObj[0].email;
        this.warrantyMailId = warrantyValObj[0].id;

        let thirdMailValObj = this.serviceAllMail.filter(function (elem) {
          if (elem.mail_type == 3) return elem;
        });
        this.third_mail = thirdMailValObj[0].email;
        this.thirdMailName = thirdMailValObj[0].email;
        this.thirdMailID = thirdMailValObj[0].id;

        let fourthMailValObj = this.serviceAllMail.filter(function (elem) {
          if (elem.mail_type == 4) return elem;
        });
        this.fourth_mail = fourthMailValObj[0].email;
        this.fourthMailName = fourthMailValObj[0].email;
        this.fourthMailID = fourthMailValObj[0].id;
      } else {
        let message = _.get(data, "message", "");
        this.serviceAllMail = [];
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    /**
     * @method fetchMTBList
     * @description Tt will fetch all mtb data
     * @author @Jeevan Poojary
     */

    async fetchMTBList() {
      this.isLoadingFetchMtb = this.$loading.show();
      let data = await this.$store.dispatch(SETTINGS_MTB_LIST, {
        filters: this.mtbFilters,
      });
      this.isLoadingFetchMtb.hide();
      if (_.get(data, "success", true)) {
        this.mtbList = _.get(data, "list", []);
        this.totalMTB = _.get(data, "total", 0);
      } else {
        let message = _.get(data, "message", "");
        this.mtbList = [];
        this.totalMTB = 0;
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    /**
     * @method saveServiceMail
     * @description Tt will save service mail data
     * @author @Jeevan Poojary
     */
    async saveServiceMail() {
      this.$v.$touch();
      if (!this.$v.serviceMail.$invalid) {
        let payload = {
          email: [this.serviceMail],
          mail_type: 1,
        };
        this.isLoadingSaveService = this.$loading.show();
        this.isLoaderOn = true;
        await this.$store.dispatch(SERVICE_MAIL_ADD, payload).then((data) => {
          if (_.get(data, "data.success", false)) {
            this.isLoadingSaveService.hide();
            this.$toast.removeAll();
            this.$toast.success({
              message: _.get(data, "data.message", ""),
            });
            this.$bvModal.hide("serverEmail");
            this.serviceMail = "";
            this.$v.serviceMail.$reset();
            this.serviceMailList();
          } else {
            this.$bvModal.hide("serverEmail");
            this.serviceMailList();
            this.isLoadingSaveService.hide();
            let message = _.get(data, "message", "");
            if (message.length > 0) {
              this.$toast.error({
                message: message,
              });
            } else {
              this.$toast.removeAll();
              let messageObj = _.get(data, "message", "");
              multipleErrorMessages(this.$toast, messageObj);
            }
          }
        });
        this.isLoadingSaveService.hide();
        this.isLoaderOn = false;
      }
    },

    /**
     * @method saveThirdMail
     * @description Tt will save warranty mail data
     * @author @Jeevan Poojary
     */
    async saveThirdMail() {
      this.$v.$touch();
      if (!this.$v.third_mail.$invalid) {
        let payload = {
          email: [this.third_mail],
          mail_type: 3,
        };
        this.isLoadingMail = this.$loading.show();
        this.isLoaderOn = true;
        await this.$store.dispatch(SERVICE_MAIL_ADD, payload).then((data) => {
          if (_.get(data, "data.success", false)) {
            this.isLoadingMail.hide();
            this.$toast.removeAll();
            this.$toast.success({
              message: _.get(data, "data.message", ""),
            });
            this.$bvModal.hide("newThirdEmail");
            this.third_mail = "";
            this.$v.third_mail.$reset();
            this.serviceMailList();
          } else {
            this.serviceMailList();
            this.$bvModal.hide("newThirdEmail");
            this.isLoadingMail.hide();
            let message = _.get(data, "message", "");
            if (message.length > 0) {
              this.$toast.error({
                message: message,
              });
            } else {
              this.$toast.removeAll();
              let messageObj = _.get(data, "message", "");
              multipleErrorMessages(this.$toast, messageObj);
            }
          }
        });
        this.isLoadingMail.hide();
        this.isLoaderOn = false;
      }
    },

    /**
     * @method saveWarrantyMail
     * @description Tt will save warranty mail data
     * @author @Jeevan Poojary
     */
    async saveFourthMail() {
      this.$v.$touch();
      if (!this.$v.fourth_mail.$invalid) {
        let payload = {
          email: [this.fourth_mail],
          mail_type: 4,
        };
        this.isLoadingFourth = this.$loading.show();
        this.isLoaderOn = true;
        await this.$store.dispatch(SERVICE_MAIL_ADD, payload).then((data) => {
          if (_.get(data, "data.success", false)) {
            this.isLoadingFourth.hide();
            this.$toast.removeAll();
            this.$toast.success({
              message: _.get(data, "data.message", ""),
            });
            this.$bvModal.hide("newFourthEmail");
            this.fourth_mail = "";
            this.$v.fourth_mail.$reset();
            this.serviceMailList();
          } else {
            this.serviceMailList();
            this.$bvModal.hide("newFourthEmail");
            this.isLoadingFourth.hide();
            let message = _.get(data, "message", "");
            if (message.length > 0) {
              this.$toast.error({
                message: message,
              });
            } else {
              this.$toast.removeAll();
              let messageObj = _.get(data, "message", "");
              multipleErrorMessages(this.$toast, messageObj);
            }
          }
        });
        this.isLoadingFourth.hide();
        this.isLoaderOn = false;
      }
    },
    /**
     * @method saveWarrantyMail
     * @description Tt will save warranty mail data
     * @author @Jeevan Poojary
     */
    async saveWarrantyMail() {
      this.$v.$touch();
      if (!this.$v.warrantyMail.$invalid) {
        let payload = {
          email: [this.warrantyMail],
          mail_type: 2,
        };
        this.isLoadingWarantyMail = this.$loading.show();
        this.isLoaderOn = true;
        await this.$store.dispatch(SERVICE_MAIL_ADD, payload).then((data) => {
          if (_.get(data, "data.success", false)) {
            this.isLoadingWarantyMail.hide();
            this.$toast.removeAll();
            this.$toast.success({
              message: _.get(data, "data.message", ""),
            });
            this.$bvModal.hide("warrantyEmail");
            this.warrantyMail = "";
            this.$v.warrantyMail.$reset();
            this.serviceMailList();
          } else {
            this.serviceMailList();
            this.$bvModal.hide("warrantyEmail");
            this.isLoadingWarantyMail.hide();
            let message = _.get(data, "message", "");
            if (message.length > 0) {
              this.$toast.error({
                message: message,
              });
            } else {
              this.$toast.removeAll();
              let messageObj = _.get(data, "message", "");
              multipleErrorMessages(this.$toast, messageObj);
            }
          }
        });
        this.isLoadingWarantyMail.hide();
        this.isLoaderOn = false;
      }
    },

    /**
     * @method fetchControllerSortList
     * @description Tt will fetch all controller data
     * @author @Jeevan Poojary
     */

    async fetchControllerSortList() {
      this.isLoading = this.$loading.show();
      let data = await this.$store.dispatch(SETTINGS_SORT_LIST, {
        type: 1,
      });
      this.isLoading.hide();
      if (_.get(data, "success", true)) {
        this.controllerList = data;
      } else {
        let message = _.get(data, "message", "");
        this.controllerList = [];

        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    /**
     * @method fetchMtbSortList
     * @description Tt will fetch all mtb data
     * @author @Jeevan Poojary
     */

    async fetchMtbSortList() {
      let data = await this.$store.dispatch(SETTINGS_SORT_LIST, {
        type: 2,
      });

      if (_.get(data, "success", true)) {
        this.mtbList = data;
      } else {
        let message = _.get(data, "message", "");
        this.mtbList = [];

        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    async getRegionsDropdownList() {
      this.search = "";
      this.regionComboList = [];
      let data = await this.$store.dispatch(SETTINGS_REGION_SORT_LIST, {
        type: 3,
      });
      if (_.get(data, "success", true)) {
        this.regionComboList = data;
        let roleSettingsOptions = [];
        let i;
        for (i = 0; i < this.regionComboList.length; i++) {
          roleSettingsOptions.push({
            text: this.regionComboList[i].name,
            value: this.regionComboList[i].id,
          });
        }
        this.allRegionsDataList = [];
        this.allRegionsDataList = _.concat(
          ...this.allRegionsDataList,
          roleSettingsOptions
        );
      } else {
        let message = _.get(data, "message", "");
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    async fetchRegionMasterSortList() {
      let data = await this.$store.dispatch(SETTINGS_REGION_SORT_LIST, {
        type: 3,
      });
      if (_.get(data, "success", true)) {
        this.regionMasterList = data;
      } else {
        let message = _.get(data, "message", "");
        this.regionMasterList = [];

        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    async submitRegionWeekOff() {
      this.$v.$touch();
      if (!this.$v.regionMasterWeekOffList.$invalid) {
        let payload = {
          regions: this.regionMasterWeekOffList
            .map(({ id, week_off }) => ({ id, week_off })) // Extract only id and week_off
            .sort((a, b) => a.id - b.id), // Optional: Sort by id
        };
        this.isLoadingSubmitNotify = this.$loading.show();
        await this.$store
          .dispatch(SETTINGS_REGION_WEEK_OFF_SAVE, payload)
          .then((data) => {
            if (_.get(data, "data.success", false)) {
              this.isLoadingSubmitNotify.hide();
              this.$toast.removeAll();
              this.$toast.success({
                message: _.get(data, "data.message", ""),
              });
              this.$v.regionMasterWeekOffList.$reset();
              this.getRegionWeekOffList();
            } else {
              console.log("LLL")
              this.$v.regionMasterWeekOffList.$reset();
              this.isLoadingSubmitNotify.hide();
              let message = _.get(data, "message", "");
              if (message.length > 0) {
                this.$toast.error({
                  message: message,
                });
              } else {
                this.$toast.removeAll();
                let messageObj = _.get(data, "message", "");
                multipleErrorMessages(this.$toast, messageObj);
              }
            }
          });
      }
    },
    async getRegionWeekOffList() {
      let data = await this.$store.dispatch(SETTINGS_REGION_WEEK_OFF_SORT_LIST);
      if (_.get(data, "success", true)) {
        this.regionMasterWeekOffList = data.sort((a, b) => {
          return a.sort_order - b.sort_order;
        });
      } else {
        let message = _.get(data, "message", "");
        this.regionMasterWeekOffList = [];

        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },
  },
  async created() {
    this.fetchControllerSortList();
    this.fetchRegionMasterSortList();
    this.fetchMtbSortList();
    this.serviceMailList();
    this.getAllRegionsListItems();
    this.getRegionsDropdownList();
    this.getRegionWeekOffList();
  },
  computed: {
    filteredItems() {
      return this.regionsAllList.filter((row) => {
        const region_name = row.region_name.toString().toLowerCase();
        const state_name = row.state_name.toLowerCase();
        const searchTerm = this.search.toLowerCase();

        return (
          region_name.includes(searchTerm) || state_name.includes(searchTerm)
        );
      });
    },
  },
};
</script>
<style>
@media (max-width: 426px) {
  .first-check {
    width: 116px !important;
  }

  .second-check {
    width: 120px !important;
  }
}

@media (max-width: 321px) {
  .d-flex {
    display: block !important;
  }

  .first-check {
    width: 66px !important;
  }
}

@media (max-width: 321px) {
  .btn-add-controller {
    display: flex !important;
  }
}

@media (max-width: 321px) {
  .controller .d-flex {
    display: flex !important;
  }
}

.settings-input .form-group {
  margin-bottom: 0rem !important;
}

.countryName {
  width: 200px !important;
}

.stateName div {
  width: 150px !important;
}

.margin-top-value {
  margin-top: 5px !important;
}

.padding-select-box {
  padding-top: 9px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.padding-value {
  padding: 5px !important;
}

.entry-custom-calender {
  font-size: 0.75rem;
  border-collapse: separate !important;
  border-spacing: 0 !important;
}
.entry-custom-calender-responsive::-webkit-scrollbar {
  width: 5px !important;
}

.entry-custom-calender-responsive::-webkit-scrollbar-thumb {
  background-color: #989898 !important;
}

.entry-custom-calender-responsive::-webkit-scrollbar-thumb {
  background-color: #989898 !important;
}
.entry-custom-calender-responsive {
  max-height: 400px !important;
}
.entry-custom-calender-responsive::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.entry-custom-calender tr td {
  border: 1px solid #e7e7e7;
  z-index: 1;
}

.entry-custom-calender tr th {
  border: 1px solid rgba(237, 241, 242, 0.5);
}

.entry-custom-calender tr .sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2;
  background: #d11016 !important;
}

.th-top-class {
  top: 0.5px !important;
}

.disabled-em-element {
  pointer-events: none !important;
  color: #989898 !important;
}

.text-bgm {
  color: #fff !important;
  font-size: 14px !important;
}

.value-bgm td {
  font-size: 14px !important;
}
.field-set-border {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #fff;
  border-color: #d8dbe0;
  background-color: #fff;
}
p#innerPara {
  padding: 10px;
}
.legend-items {
  width: 206px;
  font-size: 17px !important;
  padding-left: 5px !important;
}
.legend-items-machine {
  width: 250px;
  font-size: 17px !important;
  padding-left: 5px !important;
}
.legend-items-warranty {
  width: 220px;
  font-size: 17px !important;
  padding-left: 5px !important;
}
.legend-items-customer {
  width: 260px;
  font-size: 17px !important;
  padding-left: 5px !important;
}
</style>
