<template>
  <b-dropdown id="dropdown-1" variant="outline-secondary" class="btn-select-like" menu-class="w-100">
    <template #button-content>
      <div class="dropdown-button-content">
        <!-- Content for selected items or placeholder -->
        <div class="content-area">
          <template v-if="selectedValues.length === 0">
            <span class="placeholder-text">Select Options</span>
          </template>
          <template v-else>
            <span v-for="(label, index) in visibleLabels" :key="index" class="selected-tag">
              {{ label }}
              <b-icon icon="x-circle-fill" variant="danger" @click.stop="removeTag(label)" class="close-tag"></b-icon>
            </span>
            <span v-if="hiddenCount > 0" class="more-label">+{{ hiddenCount }} more</span>
          </template>
        </div>
        <!-- Dropdown icon on the right -->
        <b-icon icon="chevron-down" class="dropdown-icon"></b-icon>
      </div>
    </template>
    <b-dropdown-form @submit.stop.prevent>
      <b-form-group>
        <b-form-checkbox-group v-model="selectedValues" @change="updateValue" stacked>
          <b-form-checkbox v-for="option in options" :key="option.value" :value="option.value" class="checkbox-option mt-2">
            {{ option.label }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
    </b-dropdown-form>
  </b-dropdown>
</template>


<script>
export default {
  name: "MultiSelect",
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedValues: [],
      visibleCount: 2, // Adjustable based on average label size and available space
    };
  },
  computed: {
    selectedLabels() {
      return this.selectedValues.map(value =>
        this.options.find(option => option.value === value).label
      );
    },
    visibleLabels() {
      return this.selectedLabels.slice(0, this.visibleCount);
    },
    hiddenCount() {
      return this.selectedLabels.length - this.visibleCount;
    }
  },
  methods: {
    updateValue() {
      this.$emit('input', this.selectedValues);
    },
    removeTag(removalLabel) {
      const newValue = this.selectedValues.filter(value => 
        this.options.find(option => option.value === value).label !== removalLabel
      );
      this.selectedValues = newValue;
      this.updateValue();
    }
  },
  watch: {
    value(val) {
      this.selectedValues = val;
    }
  },
  mounted() {
    this.selectedValues = this.value;
  }
}
</script>
<style>
.dropdown-button-content {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures icon stays on the right */
  width: 100%;
}

.content-area {
  display: flex;
  flex-grow: 1; /* Allows the content area to expand */
  align-items: center;
  flex-wrap: wrap; /* Allows tags to wrap if needed */
}

.selected-tag {
  margin-right: 8px;
  padding: 2px;
  background-color: #e9ecef;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 0.875em;
}

.close-tag {
  font-size: 0.75em;
  margin-left: 4px;
  cursor: pointer;
}

.more-label {
  font-size: 0.875em;
  color: #495057;
}

.placeholder-text {
  color: #495057;
  opacity: 0.6;
}

.dropdown-icon {
  margin-left: 8px; /* Ensures spacing between text and icon */
}

#dropdown-1 .btn-outline-secondary {
  color: black !important;
}
#dropdown-1 .btn-outline-secondary:hover {
  background-color: white !important;
}
#dropdown-1 .btn-outline-secondary:active {
  background-color: white !important;
}
#dropdown-1 .dropdown-toggle::after {
  display: none !important;
}
#dropdown-1 .checkbox-option .custom-control-label {
  flex-grow: 1;
  padding-top: 0px !important;
}
</style>
